import "./src/styles/global.css"
import "../shared/src/styles/shared.css"

import "../shared/src/components/BuilderButton/BuilderButtonHover.css"
import "../shared/src/components/NetlifyForm/NetlifyForm.css"
import "../shared/src/components/GoogleReviews/GoogleReviews.css"

import '@fontsource/pt-sans/400.css';
import '@fontsource/pt-sans/700.css';

function initGTMOnEvent(event) {
  initGTM();
  event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
}

function initGTM() {
  if (window.gtmDidInit) {
    return false;
  }

  window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.

  var script = document.createElement('script');

  script.type = 'text/javascript';
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.GATSBY_GA_TRACKING_ID}`;

  script.onload = function () {
    window.dataLayer.push({
      // ensure PageViews are always tracked
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
      'gtm.uniqueEventId': 0,
      'branch': process.env.BRANCH
    });
  };

  document.head.appendChild(script);
}

function initCookieYes() {
  if (window.cookieYesDidInit) {
    return false;
  }

  window.cookieYesDidInit = true; // flag to ensure script does not get added to DOM more than once.

  var script = document.createElement('script');

  script.type = 'text/javascript';
  script.async = true;
  script.src ="https://cdn-cookieyes.com/client_data/760b9e5cddc9094152f6b59f/script.js";

  document.head.appendChild(script);
}

const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = "; expires=" + date.toGMTString();
  document.cookie = name + "=" + value + expires + ";path=/";
}

const getParam = (p) => {
  const match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

const storeCookies = () => {
  const gclid = getParam("gclid");
  if (gclid) {
    var gclsrc = getParam("gclsrc");
    if (!gclsrc || gclsrc.indexOf("aw") !== -1) {
      setCookie("gclid", gclid, 90);
    }
  }
};

export const onClientEntry = () => {
  document.onreadystatechange = function () {
    if (document.readyState !== 'loading') {
      setTimeout(initGTM, 3000);
      setTimeout(initCookieYes, 3000);
    }
  };
  document.addEventListener('scroll', initGTMOnEvent);
  document.addEventListener('mousemove', initGTMOnEvent);
  document.addEventListener('touchstart', initGTMOnEvent);

  storeCookies();
};